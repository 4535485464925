import dayjs from 'dayjs';
import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import styled, { css } from 'styled-components';

import colors from '../../../colors';
import Icon from '../../atoms/Icon';
import Typography from '../../atoms/Typography';
import Column from '../../layout/Column';
import Row from '../../layout/Row';
import { MIN_AVAILABLE_DATE } from '../DateSelect/dateSupport';
import { usePortalPositioning } from '../DropDown/usePortalPositioning';
import { useOutsideAlerter } from '../MultiSelect/hooks';
import { SelectPlacement, SelectSize } from '../Select';

const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
interface MonthSelectionPropsType {
  label?: string;
  width?: string;
  size?: SelectSize;
  start?: string;
  end?: string;
  disabled?: boolean;
  value?: string;
  placement?: SelectPlacement;
  selectHeight?: number;
  zIndex?: 'high' | 'highest';
  onChange?: (value: string) => void;
  dateFormat?: string;
  error?: string;
}
const StyledContainer = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2.5)};
`;

const StyledIconsContainer = styled.div`
  display: flex;
`;

const ArrowIcon = styled(Icon)<{ disabled?: boolean }>`
  ${({ theme, disabled }) => css`
    cursor: pointer;
    color: ${disabled ? theme.palette.silver : theme.palette.midnight};
    &:hover {
      transform: scale(${disabled ? 1 : 1.2});
      color: ${disabled ? theme.palette.silver : theme.palette.midnight};
    }
  `}
`;
const StyledValue = styled.div<{
  size: SelectSize;
  showSelect: boolean;
  hasError?: boolean;
  disabled?: boolean;
  bold?: boolean;
}>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: ${({ theme }) => `1px solid ${theme.palette.fieldGrey}`};
  background: ${({ theme }) => theme.palette.white};
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};

  ${({ size, showSelect, theme }) => {
    switch (size) {
      case 'tiny': {
        return css`
          height: 20px;
          padding: 0 ${theme.spacing(2)} ${showSelect ? '1px' : '0'} ${theme.spacing(2)};
          border-radius: ${theme.spacing(1)};
          & > .material-icons-round {
            right: 4px;
          }
        `;
      }
      case 'small': {
        return css`
          height: 28px;
          padding: 0 ${theme.spacing(2)} ${showSelect ? '1px' : '0'} ${theme.spacing(2)};
          border-radius: ${theme.spacing(1)};
          & > .material-icons-round {
            right: 4px;
          }
        `;
      }
      case 'medium': {
        return css`
          height: 36px;
          padding: 0 ${theme.spacing(3)} ${showSelect ? '1px' : '0'} ${theme.spacing(3)};
          border-radius: ${theme.spacing(2)};
          & > .material-icons-round {
            right: 6px;
          }
        `;
      }
      case 'large': {
        return css`
          height: 40px;
          padding: 0 ${theme.spacing(3)} ${showSelect ? '1px' : '0'} ${theme.spacing(4)};
          border-radius: ${theme.spacing(2)};
          & > .material-icons-round {
            right: 8px;
          }
        `;
      }
      case 'xlarge': {
        return css`
          height: 44px;
          padding: 0 ${theme.spacing(4)} ${showSelect ? '1px' : '0'} ${theme.spacing(5)};
          border-radius: ${theme.spacing(2)};
          & > .material-icons-round {
            right: 10px;
          }
        `;
      }
      default:
        return '';
    }
  }};
  ${({ hasError, theme }) => {
    if (hasError) {
      return css`
        border: 1px solid ${theme.palette.danger.dark};
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
      `;
    }
  }}

  ${({ showSelect }) =>
    showSelect
      ? css`
          border-bottom: unset;
          border-bottom-left-radius: unset;
          border-bottom-right-radius: unset;
        `
      : ''}

  &:hover,
  &:focus {
    filter: brightness(85%);
  }
  &:active {
    filter: brightness(70%);
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const MonthElement = styled(Typography)<{ status: string }>`
  ${({ theme, status }) => css`
    display: flex;
    width: 80px;
    cursor: pointer;
    padding: 8px 0;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex-shrink: 0;
    border-radius: 6px;
    border: 1px solid;
    background-color: ${status === 'active'
      ? theme.palette.primary.main
      : status === 'disabled'
      ? theme.palette.silver
      : theme.palette.white};

    border-color: ${status === 'current' ? theme.palette.primary.main : theme.palette.silver};
    color: ${status === 'active'
      ? theme.palette.white
      : status === 'current'
      ? theme.palette.primary.main
      : theme.palette.midnight};
    opacity: ${status === 'disabled' ? 0.5 : 1};

    &:hover {
      transform: scale(${status === 'disabled' ? 1 : 1.05});
      border-color: ${status !== 'disabled' ? theme.palette.primary.lighter : theme.palette.silver};
    }
  `}
`;

const StyledSelect = styled.div<{
  size: SelectSize;
  placement: SelectPlacement;
  container: DOMRect;
  select: { width: number; height: number };
  zIndex: 'high' | 'highest';
}>`
  position: absolute;
  width: 300px;
  overflow-y: auto;
  z-index: ${({ theme, zIndex }) => theme.zIndex[zIndex]};
  border: 1px solid ${({ theme }) => theme.palette.fieldGrey};
  border-top: unset;
  background-color: ${({ theme }) => theme.palette.white};

  ${({ placement, container: { top, left, width, height }, select: { height: selectHeight, width: selectWidth } }) => {
    const usedWidth = selectWidth;
    switch (placement) {
      case 'topStart':
        return css`
          top: calc(${top}px - ${selectHeight}px + ${window.scrollY}px);
          left: calc(${left}px + ${window.scrollX}px);
        `;
      case 'bottomStart':
        return css`
          top: calc(${top}px + ${height}px + ${window.scrollY}px);
          left: calc(${left}px + ${window.scrollX}px);
        `;
      case 'bottomEnd':
        return css`
          top: ${top + height + window.scrollY}px;
          left: ${left - usedWidth + width + window.scrollX}px;
        `;
    }
  }};

  ${({ size, theme }) => {
    switch (size) {
      case 'tiny':
      case 'small': {
        return css`
          border-bottom-left-radius: ${theme.spacing(1)};
          border-bottom-right-radius: ${theme.spacing(1)};
        `;
      }
      case 'medium':
      case 'large':
      case 'xlarge': {
        return css`
          border-bottom-left-radius: ${theme.spacing(2)};
          border-bottom-right-radius: ${theme.spacing(2)};
        `;
      }
      default:
        return '';
    }
  }}
`;

const SelectionArea = styled(Column)`
  ${({ theme }) => css`
    padding: 8px;
    padding-bottom: 20px;
  `}
`;

const MonthSelection: React.FC<MonthSelectionPropsType> = ({
  label,
  width = '105px',
  size = 'small',
  disabled,
  placement = 'bottomStart',
  zIndex = 'high',
  onChange,
  value,
  start = MIN_AVAILABLE_DATE,
  end = dayjs().format('YYYY-12'),
  error,
}) => {
  const minYear = start && dayjs(start).isValid() ? dayjs(start).year() : null;
  const maxYear = end && dayjs(end).isValid() ? dayjs(end).year() : null;

  const currentYear = dayjs().year();

  const [showSelect, setShowSelect] = useState(false);
  const showSelectRef = useRef(false);
  const [yearValue, setYearValue] = useState(currentYear);
  const [displayValue, setDisplayValue] = useState(
    value && dayjs(value).isValid() ? dayjs(value).format('MMM YYYY') : 'Select month',
  );
  const disableMinYear = Boolean(minYear && yearValue <= minYear);
  const disableMaxYear = Boolean(maxYear && yearValue >= maxYear);
  const {
    containerCallbackRef,
    containerRect,
    elementRef: selectRef,
    elementCallbackRef: selectCallbackRef,
    elementDimensions: selectDimensions,
  } = usePortalPositioning(showSelect, setShowSelect);
  useOutsideAlerter(selectRef, () => handleDone());

  useEffect(() => {
    showSelectRef.current = showSelect;
    setYearValue(dayjs(value).year());
  }, [showSelect]);
  useEffect(() => {
    if (value && dayjs(value).isValid()) {
      setDisplayValue(dayjs(value).format('MMM YYYY'));
      setYearValue(dayjs(value).year());
    }
  }, [value]);

  const handleItemClick = (month: number) => {
    setDisplayValue(dayjs().set('month', month).set('year', yearValue).set('date', 1).format('MMM YYYY'));
    onChange?.(dayjs().set('month', month).set('year', yearValue).set('date', 1).format('YYYY-MM'));

    setShowSelect(false);
  };

  const handleDone = () => {
    if (showSelectRef.current) {
      if (dayjs(displayValue).isValid()) {
        onChange?.(dayjs(displayValue).format('YYYY-MM'));
      }
      setShowSelect(false);
    }
  };

  const getMonthStatus = (value: string) => {
    if (
      (Boolean(start) && dayjs(start).isAfter(dayjs(value), 'month')) ||
      (Boolean(end) && dayjs(end).isBefore(dayjs(value), 'month'))
    ) {
      return 'disabled';
    }
    if (dayjs(displayValue).isValid() && dayjs(displayValue).isSame(value)) {
      return 'active';
    }
    if (dayjs().isSame(dayjs(value), 'month')) {
      return 'current';
    }
    return 'initial';
  };

  const typographySize =
    size === 'small' || size === 'medium' ? 'small' : size === 'large' || size === 'xlarge' ? 'medium' : size;

  const iconSize = size === 'small' ? 4.5 : size === 'medium' ? 5 : size === 'large' ? 5.5 : size === 'xlarge' ? 6 : 4;
  return (
    <StyledContainer ref={containerCallbackRef} width={width}>
      {label && <Typography weight="regular">{label}</Typography>}
      <StyledValue
        size={size}
        disabled={disabled}
        onClick={() => !disabled && setShowSelect(!showSelect)}
        showSelect={showSelect}
        hasError={Boolean(error)}
      >
        <Typography size={typographySize} color={'black'} weight={'medium'}>
          {displayValue}
        </Typography>
        <StyledIconsContainer>
          <Icon size={iconSize} name="expand_more" color={colors.blue[600]} rounded marginRight={0} />
        </StyledIconsContainer>
      </StyledValue>
      {error && (
        <Typography size="small" color="danger" weight="regular">
          {error}
        </Typography>
      )}
      {showSelect &&
        createPortal(
          <StyledSelect
            ref={selectCallbackRef}
            size={size}
            placement={placement}
            container={containerRect}
            select={selectDimensions}
            zIndex={zIndex}
          >
            <SelectionArea width="100%" hAlign="center">
              <Row width="80%" hAlign="space-between" vAlign="center">
                <ArrowIcon
                  disabled={disableMinYear}
                  name="arrow_back_ios"
                  clickable
                  onClick={() => (!disableMinYear ? setYearValue(prev => prev - 1) : {})}
                />
                <Typography>{yearValue}</Typography>
                <ArrowIcon
                  disabled={disableMaxYear}
                  name="arrow_forward_ios"
                  onClick={() => (!disableMaxYear ? setYearValue(prev => prev + 1) : {})}
                />
              </Row>
              <Row wrap spacing="small" hAlign="center">
                {months.map((month, index) => {
                  const monthStatus = getMonthStatus(`${yearValue}-${index + 1}`);
                  return (
                    <MonthElement
                      key={month}
                      size="medium"
                      status={monthStatus}
                      weight="regular"
                      onClick={() => (monthStatus === 'disabled' ? {} : handleItemClick(index))}
                    >
                      {month}
                    </MonthElement>
                  );
                })}
              </Row>
            </SelectionArea>
          </StyledSelect>,
          document.body,
        )}
    </StyledContainer>
  );
};

export default MonthSelection;
