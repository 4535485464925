import React from 'react';
import styled from 'styled-components';

import { ConnectionStatuses } from '../../../features/connections/ConnectionsContext';
import { Organisation } from '../../../store/state/organisations/types';
import { theme } from '../../../theme';
import TextButton from '../../atoms/TextButton';
import Typography from '../../atoms/Typography';
import Column from '../../layout/Column';
import Row from '../../layout/Row';
import Card from '../Card';
import { getCurrencyName } from '../CurrencySelect/currencySupport';

interface OnboardingOrganisationCardPropsType {
  organistion: Organisation;
  handleConnect: () => void;
  connections?: ConnectionStatuses;
}
const OnBoardingOrganisationCard = styled(Card)`
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0px 1px 4px 2px rgba(0, 0, 0, 0.05);
`;
const StyledStatus = styled.div`
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${theme.palette.success.offwhite};
  border-radius: 50%;

  &::after {
    content: '';
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background-color: ${theme.palette.success.lighter};
  }
`;

const AccountingSystemBanner = styled.div`
  width: 180px;
  height: 80px;
  border-radius: 8px;
  position: relative;

  & > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: inherit;
  }
`;
const OnboardingOrganisationCard: React.FC<OnboardingOrganisationCardPropsType> = ({
  organistion,
  handleConnect,
  connections,
}) => {
  let connectedConnection = null;
  if (connections && connections['xero'].status === 'connected') {
    connectedConnection = connections['xero'];
  } else if (connections && connections['quickbooks'].status === 'connected') {
    connectedConnection = connections['quickbooks'];
  }
  return (
    <OnBoardingOrganisationCard width="902px" height="114px">
      <Row width="100%" vAlign="center" hAlign="space-between">
        <Column spacing="tiny">
          <Typography size="h4" weight="semibold">
            {organistion.name} {organistion.name_short}
          </Typography>
          <Typography size="medium" weight="regular">
            {getCurrencyName(organistion.default_currency_code)}
          </Typography>
        </Column>
        {connectedConnection && (
          <Row vAlign="center" spacing="xxlarge">
            {connectedConnection.source_name === 'xero' && (
              <AccountingSystemBanner>
                <img src={'/images/logos/xero-banner.png'} alt="xero logo" />
              </AccountingSystemBanner>
            )}
            {connectedConnection.source_name === 'quickbooks' && (
              <AccountingSystemBanner>
                <img src={'/images/logos/quickbooks-banner.png'} alt="quickbooks logo" />
              </AccountingSystemBanner>
            )}
            <Column spacing="tiny">
              <Typography size="h5" weight="regular">
                {connectedConnection.source_name === 'xero'
                  ? 'Xero'
                  : connectedConnection.source_name === 'quickbooks'
                  ? 'QuickBooks'
                  : connectedConnection.source_name}
              </Typography>
              <Row vAlign="center" spacing="tiny">
                <StyledStatus />
                <Typography size="medium" color="secondary">
                  Connected
                </Typography>
              </Row>
            </Column>
            <Column hAlign="center" spacing="tiny">
              <Typography size="medium" weight="medium" color="secondary">
                Data imported
              </Typography>
            </Column>
          </Row>
        )}
        {!connectedConnection && (
          <TextButton variant="secondary" size="xlarge" width="179px" iconRight="arrow_forward" onClick={handleConnect}>
            Connect
          </TextButton>
        )}
      </Row>
    </OnBoardingOrganisationCard>
  );
};

export default OnboardingOrganisationCard;
