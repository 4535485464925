import dayjs from 'dayjs';
import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Notification } from 'rsuite';
import { MIN_AVAILABLE_DATE } from 'scalexp/components/molecules/DateSelect/dateSupport';
import styled, { css } from 'styled-components';
import useSWR, { mutate } from 'swr';
import { useQueryParam } from 'use-query-params';
import { object } from 'yup';

import { useConnectionsContext } from '../../../features/connections/ConnectionsContext';
import { useSyncContext } from '../../../features/connections/sync-context/SyncContext';
import { ImportJob } from '../../../features/connections/sync-context/types';
import { useAccountGroups } from '../../../store/state/accountGroups/hooks';
import { useAccounts } from '../../../store/state/accounts/hooks';
import { theme } from '../../../theme';
import fetcher from '../../../utils/fetcher';
import Icon from '../../atoms/Icon';
import Loader from '../../atoms/Loader';
import TextButton from '../../atoms/TextButton';
import Typography from '../../atoms/Typography';
import useActiveOrganisation from '../../contexts/OrganisationContext/useActiveOrganisation';
import useActiveOrganisationId from '../../contexts/OrganisationContext/useActiveOrganisationId';
import Column from '../../layout/Column';
import Row from '../../layout/Row';
import Spacer from '../../layout/Spacer';
import { getCurrencyName } from '../../molecules/CurrencySelect/currencySupport';
import Form from '../../molecules/Form';
import FormFieldSelect from '../../molecules/FormFieldSelect';
import MonthSelection from '../../molecules/MonthSelection';
import { generateDateRanges } from './helpers';

interface FinancialConfigPageProps {
  // optional action to be executed when the sync starts,
  // used for onboarding flow to redirect user to the next step
  startSyncHandler?: Function;
}

const StyledContainer = styled.div`
  height: 100%;
  margin-bottom: 60px;
`;

const StyledContent = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.spacing(7)};
  padding: ${({ theme }) => `${theme.spacing(8)} ${theme.spacing(10)}`};
`;

const StyledImage = styled.img`
  height: 90px;
  margin-bottom: ${({ theme }) => theme.spacing(2)};
  border-radius: ${({ theme }) => theme.spacing(1)};
`;

const LockContainer = styled.div`
  .lock {
    position: absolute;
    right: 13px;
    top: 47px;
    z-index: 10;
  }
  &:hover {
    .tooltip {
      display: block;
    }
  }
`;
const StyledTooltip = styled.div`
  display: none;
  position: absolute;
  right: -${({ theme }) => theme.sizing(48.75)};
  top: ${({ theme }) => theme.sizing(19.5)};
  padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(4)}`};
  border-radius: 14px;
  background-color: ${({ theme }) => theme.palette.midnight};
  z-index: 10;

  &::after {
    content: '';
    position: absolute;
    bottom: 100%;
    left: ${({ theme }) => theme.sizing(92.5)};
    margin-left: -10px;
    border-width: 10px;
    border-style: solid;
    border-color: transparent transparent ${({ theme }) => theme.palette.midnight} transparent;
  }
`;

const StyledInfoMessage = styled.div`
  height: fit-content;
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(6)}`};
  border-radius: ${({ theme }) => theme.spacing(2.5)};
  background-color: ${({ theme }) => theme.palette.primary.offwhite};
`;

const StyledRowInput = styled(Row)<{ isOnFocus: boolean }>`
  padding-left: 24px;
  gap: 8px;
  justify-content: space-between;
  & > *:first-child {
    min-width: 400px;
    width: 100%;
    max-width: 650px;
    position: relative;
  }
  & > *:last-child {
    visibility: hidden;
    height: 0;
  }
  ${({ isOnFocus, theme }) => {
    if (isOnFocus) {
      return css`
        padding: 18px 24px;
        position: relative;
        background-color: ${theme.palette.backgroundGrey};
        border-radius: 16px;
        & > *:last-child {
          visibility: visible;
          height: auto;
        }
      `;
    }
  }}
`;

const FormMessageInfoContainer = styled(Row)`
  padding: 18px;
  background-color: #d1defc;
  border-radius: 8px;
  max-width: 398px;
  min-width: 250px;
  & > *:first-child {
    position: relative;
    top: 5px;
  }
`;

const SyncButtonContainer = styled(Row)`
  margin-top: 60px;
`;

const FieldContainer = styled.div`
  margin-top: 20px;
  width: calc(100vw - 300px);
`;

const FieldIndex = styled(Typography)`
  width: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.granite};
`;

const FormInfoMessage: React.FC<React.PropsWithChildren> = ({ children }) => (
  <FormMessageInfoContainer vAlign="flex-start" spacing="small">
    <Icon name="info" color={theme.palette.granite} size={5} />
    {children}
  </FormMessageInfoContainer>
);

const fieldWidthPx = 400;

const formSchema = object().shape({});

const LockItemTooltip = () => {
  return (
    <LockContainer>
      <Icon size={6} name="lock" color={theme.palette.granite} className="lock" />
      <StyledTooltip className="tooltip">
        <Typography weight="regular" color="white">
          This field is locked. If you need to make a change, please contact Customer Success <br /> via the chat button
          or on Support@ScaleXP.com
        </Typography>
      </StyledTooltip>
    </LockContainer>
  );
};

const FinancialConfigPage: React.FC<React.PropsWithChildren<FinancialConfigPageProps>> = () => {
  const history = useHistory();
  const { connection: connectionName } = useParams<{ connection: string }>();
  const [subsidiaryIdParams] = useQueryParam<string | undefined>('organisation_id');
  const [onboarding] = useQueryParam<string | undefined>('onboarding');
  let organisation = useActiveOrganisation();
  const subsidiaryId =
    parseInt(subsidiaryIdParams!) === organisation.organisation_id ? undefined : parseInt(subsidiaryIdParams!);
  const parentOrganisationId = useActiveOrganisationId();
  const organisationId = subsidiaryId ? subsidiaryId : parentOrganisationId;
  if (subsidiaryId) {
    organisation = organisation.children.find(org => org.organisation_id === subsidiaryId)!;
  }

  const config = useSWR(`/api/v1/organisations/${organisationId}/connections/${connectionName}/configuration`);
  const displayName =
    connectionName === 'sageone'
      ? 'Sage'
      : connectionName === 'quickbooks'
      ? 'QuickBooks'
      : connectionName[0].toUpperCase() + connectionName.slice(1);

  const [focusedInput, setFocusedInput] = useState<
    'actualsStart' | 'budgetsStart' | 'autoSyncBudgets' | 'customersStart' | 'trackingCodes' | 'dateFormat' | ''
  >('');
  const { jobs, setJobs } = useSyncContext();
  const { reload: reloadAccounts } = useAccounts(organisationId);
  const { reload: reloadGrouping } = useAccountGroups();

  const [syncing, setSyncing] = useState(false);
  const {
    revalidate: refetchConnections,
    connections,
    isLoaded: isLoadedConnectionContext,
    consolidatedConnections,
  } = useConnectionsContext();
  const consolidatedConnection =
    consolidatedConnections && consolidatedConnections.find(({ organisation_id }) => organisation_id === subsidiaryId);
  const connection = subsidiaryId
    ? consolidatedConnection && consolidatedConnection.connections[connectionName]
    : connections[connectionName];

  if (!isLoadedConnectionContext || config.isValidating) {
    return <Loader vertical backdrop />;
  }

  if (!isLoadedConnectionContext && subsidiaryId) {
    return <Loader vertical backdrop />;
  }

  if (!connection) {
    return <Loader vertical backdrop />;
  }

  const updateConfig = async (config: object) => {
    try {
      await fetcher(`/api/v1/organisations/${organisationId}/connections/${connectionName}/configuration`, {
        method: 'put',
        body: JSON.stringify({
          config: config,
        }),
      });
      await mutate(
        `/api/v1/organisations/${organisationId}/connections/${connectionName}/configuration`,
        {
          ...config,
        },
        {
          revalidate: false,
        },
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleActualsStartChange = (value: string) => {
    updateConfig({
      ...config.data,
      actuals: {
        ...config.data.actuals,
        start_date: dayjs(value).startOf('month').format('YYYY-MM-DD'),
      },
    });
  };

  const handleCustomersStartChange = (value: string) => {
    updateConfig({
      ...config.data,
      documents: {
        ...config.data.documents,
        start_date: dayjs(value).startOf('month').format('YYYY-MM-DD'),
      },
    });
  };

  const handleAutoSyncBudgetsChange = (value: string) => {
    updateConfig({
      ...config.data,
      budget: {
        ...config.data.budget,
        auto_sync: !(value === 'disable-auto-sync'),
        start_date: value !== 'disable-auto-sync' ? value : config.data.budget.start_date,
      },
    });
  };

  const handleImportTrackingCategoriesChange = (value: boolean) => {
    updateConfig({
      ...config.data,
      tracking_categories: value,
    });
  };

  const handleSync = async () => {
    setSyncing(true);
    try {
      let res = null;
      if (connectionName === 'xero') {
        res = await fetcher(`/api/v1/organisations/${organisationId}/connections/xero/import`, {
          method: 'post',
        });
      } else if (connectionName === 'quickbooks') {
        res = await fetcher(`/api/v1/organisations/${organisationId}/connections/quickbooks/import`, {
          method: 'post',
        });
      } else if (connectionName === 'netvisor') {
        res = await fetcher(`/api/v1/organisations/${organisationId}/connections/netvisor/import`, {
          method: 'post',
        });
      } else if (connectionName === 'hubspot') {
        res = await fetcher(`/api/v1/organisations/${organisationId}/connections/hubspot/import`, {
          method: 'post',
        });
      } else if (connectionName === 'pipedrive') {
        res = await fetcher(`/api/v1/organisations/${organisationId}/connections/pipedrive/import`, {
          method: 'post',
        });
      } else if (connectionName === 'salesforce') {
        res = await fetcher(`/api/v1/organisations/${organisationId}/connections/salesforce/import`, {
          method: 'post',
        });
      } else if (connectionName !== 'salesforce') {
        res = await fetcher(`/api/v1/organisations/${organisationId}/connections/codat/import`, {
          method: 'post',
        });
      }
      if (res && res.import_job_id) {
        const newJob: ImportJob = {
          task: 'IMPORT',
          id: res.import_job_id,
          name: connectionName,
          status: 'Running',
          completedSteps: 0,
          organisationName: organisation.name,
        };
        const newJobs = [...jobs, newJob];
        setJobs(newJobs);
      }

      // refetch accounts
      if (!['hubspot', 'salesforce', 'pipedrive'].includes(connectionName)) {
        refetchConnections();
        reloadAccounts?.();
        reloadGrouping?.();
      }
    } catch (err) {
      Notification.error({
        title: `Failed to load your data from ${displayName}.`,
        // description: 'If the problem persists, please contact us at support@scalexp.com ',
        placement: 'bottomEnd',
        duration: 0,
      });
      console.log(err);
    }
    setSyncing(false);

    if (onboarding) {
      if (subsidiaryId) {
        history.push(`/connections/onboarding`);
      } else {
        history.push(`/connections/onboarding/settings`);
      }
    } else {
      history.push('/oauth');
    }
  };

  const handleSave = () => {
    history.push('/oauth');
  };

  const neverImported = connection.status === 'connected' && !connection.last_completed_import;
  // @ts-ignore
  const canChange: boolean = neverImported || connection.source_name === 'xero';
  let logo = `/images/logos/${connectionName}-banner.${connectionName === 'clearbooks' ? 'jpg' : 'png'}`;

  const handleDateFormatChange = (value: string) => {
    updateConfig({
      ...config.data,
      date_format: value,
    });
  };

  // Lock the update of the settings if the connection is xero or quickbooks and it's not onboarding.
  const lockUpdate = !neverImported && (connectionName === 'xero' || connectionName === 'quickbooks');
  const isChangeDisabled = !canChange || lockUpdate;

  return (
    <StyledContainer>
      {onboarding && (
        <StyledContent>
          <Row>
            <Column>
              <Typography size="h4" weight="semibold">
                {organisation.name}
              </Typography>
              <Typography size="medium" weight="regular">
                {getCurrencyName(organisation.default_currency_code)}
              </Typography>
            </Column>
          </Row>
        </StyledContent>
      )}

      <StyledContent>
        <Row>
          <Column>
            <StyledImage
              src={logo}
              alt={`configure-${connectionName}`}
              height={connectionName === 'quickbooks' ? '64px' : undefined}
            />
          </Column>
          <Column>
            <Typography size="h5" weight="regular">
              Connection settings
            </Typography>
            <Typography size="medium" weight="regular" color="secondary">
              Select the data to import into ScaleXP
            </Typography>
          </Column>
        </Row>
      </StyledContent>

      <Spacer padding={{ left: 12 }}>
        <Form schema={formSchema} onSubmit={() => {}}>
          <FieldContainer>
            <StyledRowInput
              spacing="xxlarge"
              isOnFocus={focusedInput === 'actualsStart'}
              onClick={() => neverImported && setFocusedInput('actualsStart')}
            >
              <Column spacing="small">
                <Row vAlign="center" spacing="small">
                  <FieldIndex size="small" color="white" weight="regular">
                    1
                  </FieldIndex>
                  <Typography color="black" size="medium" weight="regular">
                    Financial Data (P&L, Balance Sheet)
                  </Typography>
                </Row>
                <MonthSelection
                  disabled={isChangeDisabled}
                  width="100%"
                  size="xlarge"
                  onChange={handleActualsStartChange}
                  value={config.data.actuals.start_date}
                  start={MIN_AVAILABLE_DATE}
                  end={dayjs().format('YYYY-MM')}
                />
                {isChangeDisabled && <LockItemTooltip />}
              </Column>
              <FormInfoMessage>
                <Typography weight="regular" color="black" size="medium">
                  Select financial data start date. It is best to include at least 12 months. You can also select the
                  start of the previous financial year, or longer if you wish.
                </Typography>
              </FormInfoMessage>
            </StyledRowInput>
          </FieldContainer>

          <FieldContainer>
            <StyledRowInput
              spacing="xxlarge"
              isOnFocus={focusedInput === 'customersStart'}
              onClick={() => neverImported && setFocusedInput('customersStart')}
            >
              <Column spacing="small">
                <Row vAlign="center" spacing="small">
                  <FieldIndex size="small" color="white" weight="regular">
                    2
                  </FieldIndex>
                  <Typography color="black" size="medium" weight="regular">
                    Invoice Data
                  </Typography>
                </Row>
                <MonthSelection
                  value={config.data.documents.start_date}
                  size="xlarge"
                  start={MIN_AVAILABLE_DATE}
                  end={dayjs().format('YYYY-MM')}
                  width="100%"
                  disabled={isChangeDisabled}
                  onChange={handleCustomersStartChange}
                />

                {!canChange || (lockUpdate && <LockItemTooltip />)}
              </Column>
              <FormInfoMessage>
                <Typography weight="regular" color="black" size="medium">
                  Select the date from which to import your invoice detail. It is best to include at least 12 months or
                  data. If you have contracts that are longer than 12 months, select a period such that all contracts
                  are included.
                </Typography>
              </FormInfoMessage>
            </StyledRowInput>
          </FieldContainer>

          <FieldContainer>
            {['xero', 'quickbooks'].includes(connectionName) &&
              (!neverImported && config.data.tracking_categories ? (
                <LockContainer style={{ position: 'relative' }}>
                  <Spacer margin={{ left: 6 }}>
                    <Row spacing="small">
                      <FieldIndex size="small" color="white" weight="regular">
                        3
                      </FieldIndex>
                      <Typography size="medium" weight="regular" color="secondary">
                        Tracking categories will import
                      </Typography>
                    </Row>
                    <Row>
                      <Typography size="small" weight="regular" color="secondary">
                        To disable importing tracking categories, please email support@scalexp.com
                      </Typography>
                    </Row>
                  </Spacer>
                </LockContainer>
              ) : (
                <StyledRowInput spacing="xxlarge" isOnFocus={focusedInput === 'trackingCodes'}>
                  <Column spacing="small">
                    <Row vAlign="center" spacing="small">
                      <FieldIndex size="small" color="white" weight="regular">
                        3
                      </FieldIndex>
                      <Typography color="black" size="medium" weight="regular">
                        Tracking Codes
                      </Typography>
                    </Row>
                    <FormFieldSelect
                      onClick={() => setFocusedInput('trackingCodes')}
                      name={'trackingCodes'}
                      defaultValue={config.data.tracking_categories}
                      selectHeight={250}
                      customSize="xlarge"
                      selectWidth={fieldWidthPx}
                      disabled={lockUpdate}
                      width={`100%`}
                      placeholder="Select to import tracking codes"
                      data={[
                        {
                          value: true,
                          label: 'Import tracking codes',
                        },
                        {
                          value: false,
                          label: 'Do not import tracking codes',
                        },
                      ]}
                      onChange={handleImportTrackingCategoriesChange}
                    />
                    {lockUpdate && <LockItemTooltip />}
                  </Column>
                  <FormInfoMessage>
                    <Typography weight="regular" color="black" size="medium">
                      Select this option if you use Xero Tracking Codes or QuickBooks Classes and would like to import
                      them into ScaleXP.
                    </Typography>
                  </FormInfoMessage>
                </StyledRowInput>
              ))}
          </FieldContainer>

          <FieldContainer>
            <LockContainer>
              <StyledRowInput spacing="xxlarge" isOnFocus={focusedInput === 'autoSyncBudgets'}>
                <Column spacing="small">
                  <Row vAlign="center" spacing="small">
                    <FieldIndex size="small" color="white" weight="regular">
                      4
                    </FieldIndex>
                    <Typography color="black" size="medium" weight="regular">
                      Budgets & Forecasts
                    </Typography>
                  </Row>
                  <FormFieldSelect
                    onClick={() => setFocusedInput('autoSyncBudgets')}
                    name={'autoSyncBudgets'}
                    defaultValue={!config.data.budget.auto_sync ? 'disable-auto-sync' : config.data.budget.start_date}
                    selectHeight={250}
                    customSize="xlarge"
                    selectWidth={fieldWidthPx}
                    width={`100%`}
                    disabled={lockUpdate}
                    placeholder="Select to import a budget or forecast"
                    data={[
                      {
                        value: 'disable-auto-sync',
                        label: 'Do not import budgets & forecasts',
                      },
                      ...generateDateRanges(dayjs('2018-01').startOf('year'), dayjs().startOf('month')).map(date => ({
                        value: date,
                        label: dayjs(date).format('MMM YYYY'),
                      })),
                    ]}
                    onChange={handleAutoSyncBudgetsChange}
                  />
                  {lockUpdate && <LockItemTooltip />}
                </Column>
                <FormInfoMessage>
                  <Typography weight="regular" color="black" size="medium">
                    If you wish to import one or more budgets from your accounting system, select the Start Date for the
                    budget(s) you wish to upload.
                  </Typography>
                </FormInfoMessage>
              </StyledRowInput>
            </LockContainer>
          </FieldContainer>

          <FieldContainer>
            <StyledRowInput spacing="xxlarge" isOnFocus={focusedInput === 'dateFormat'}>
              <Column spacing="small">
                <Row vAlign="center" spacing="small">
                  <FieldIndex size="small" color="white" weight="regular">
                    5
                  </FieldIndex>
                  <Typography color="black" size="medium" weight="regular">
                    Date format
                  </Typography>
                </Row>
                <FormFieldSelect
                  onClick={() => neverImported && setFocusedInput('dateFormat')}
                  name={'dateFormat'}
                  defaultValue={config.data.date_format}
                  selectHeight={250}
                  customSize="xlarge"
                  selectWidth={fieldWidthPx}
                  width={`100%`}
                  placeholder="Change date format"
                  data={[
                    { label: 'British (Day Month Year)', value: 'DMY' },
                    { label: 'American (Month Day Year)', value: 'MDY' },
                  ]}
                  onChange={handleDateFormatChange}
                  disabled={isChangeDisabled}
                />
                {isChangeDisabled && <LockItemTooltip />}
              </Column>
              <FormInfoMessage>
                <Typography weight="regular" color="black" size="medium">
                  If your invoices use the American date format (Month/Day/Year), then change the Date Format default,
                  initially set to the British/International system of Day/Month/Year.
                </Typography>
              </FormInfoMessage>
            </StyledRowInput>
          </FieldContainer>
          {neverImported ? (
            <SyncButtonContainer>
              <Column>
                <StyledInfoMessage>
                  <Typography weight="regular" color="black" size="medium">
                    <Icon size={4} marginRight={2} outlined name="info" color="inherit" />
                    Auto-sync will run for both actual and budgets, every morning at 7am GMT.
                  </Typography>
                </StyledInfoMessage>
              </Column>
              <Column>
                <TextButton size="large" width="160px" iconRight="refresh" onClick={handleSync} disabled={syncing}>
                  Sync
                </TextButton>
              </Column>
            </SyncButtonContainer>
          ) : (
            <SyncButtonContainer style={{ marginLeft: 20 }}>
              <TextButton size="large" width="100px" onClick={handleSave}>
                Save
              </TextButton>
            </SyncButtonContainer>
          )}
        </Form>
      </Spacer>
    </StyledContainer>
  );
};

export default FinancialConfigPage;
