import React, { useState, useEffect, useRef, useMemo, PropsWithChildren, Fragment } from 'react';
import { createPortal } from 'react-dom';
import Tooltip from 'scalexp/components/molecules/Tooltip';
import styled, { css } from 'styled-components';

import colors from '../../../colors';
import Divider from '../../atoms/Divider';
import Icon from '../../atoms/Icon';
import Typography from '../../atoms/Typography';
import { useValidationContext } from '../../contexts/ValidationContext';
import Row from '../../layout/Row';
import { usePortalPositioning } from '../DropDown/usePortalPositioning';
import { useFirstRender, useOutsideAlerter } from '../MultiSelect/hooks';
import SearchInput from '../SearchInput';

export type SelectSize = 'tiny' | 'small' | 'medium' | 'large' | 'xlarge';
export type SelectPlacement = 'topStart' | 'topEnd' | 'bottomStart' | 'bottomEnd';

type Groups<Value> = {
  [group: string]: { items: DataItem<Value>[]; isExpanded: boolean; order: number };
};

export interface DataItem<Value = string> {
  label: string;
  value: Value;
  [key: string]: any;
}

export type SelectData<Value> = (DataItem<Value> | 'Divider')[];

export interface SelectProps<Value> {
  data: SelectData<Value>;
  size?: SelectSize;
  width?: string;
  placeholder?: React.ReactNode;
  value?: Value;
  searchable?: boolean;
  selectWidth?: number | 'maxOptionWidth';
  selectHeight?: number;
  selectPlaceholder?: string;
  placement?: SelectPlacement;
  alignment?: 'Start' | 'End';
  clearable?: boolean;
  expandableGroups?: boolean;
  showCheckMark?: boolean;
  label?: React.ReactNode;
  tooltip?: React.ReactNode;
  disabled?: boolean;
  groupBy?: string;
  orderGroups?: 'ASCENDING' | 'DESCENDING';
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  onChange?: (value: Value) => void;
  onClose?: (value: Value | null) => void;
  onSearchChange?: (value: string) => void;
  renderValue?: (value: Value, item: DataItem<Value>) => React.ReactNode;
  renderSelectItem?: (value: Value, item: DataItem<Value>) => React.ReactNode;
  renderSelectGroup?: (groupName: string) => React.ReactNode;
  bold?: boolean;
  customDisplayLabel?: string;
  zIndex?: 'high' | 'highest';
  showArrow?: boolean;
}

const StyledContainer = styled.div<{ width: string }>`
  width: ${({ width }) => width};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(2.5)};
`;

const StyledValue = styled.div<{
  size: SelectSize;
  showSelect: boolean;
  hasError?: boolean;
  disabled?: boolean;
  bold?: boolean;
  placement: SelectPlacement;
}>`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: space-between;
  border: ${({ theme }) => `1px solid ${theme.palette.fieldGrey}`};
  background: ${({ theme }) => theme.palette.white};
  opacity: ${({ disabled }) => (disabled ? '0.2' : '1')};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};

  ${({ size, showSelect, theme }) => {
    switch (size) {
      case 'tiny': {
        return css`
          height: 20px;
          padding: 0 ${theme.spacing(2)} ${showSelect ? '1px' : '0'} ${theme.spacing(2)};
          border-radius: ${theme.spacing(1)};
          & > .material-icons-round {
            right: 4px;
          }
        `;
      }
      case 'small': {
        return css`
          height: 28px;
          padding: 0 ${theme.spacing(2)} ${showSelect ? '1px' : '0'} ${theme.spacing(2)};
          border-radius: ${theme.spacing(1)};
          & > .material-icons-round {
            right: 4px;
          }
        `;
      }
      case 'medium': {
        return css`
          height: 36px;
          padding: 0 ${theme.spacing(3)} ${showSelect ? '1px' : '0'} ${theme.spacing(3)};
          border-radius: ${theme.spacing(2)};
          & > .material-icons-round {
            right: 6px;
          }
        `;
      }
      case 'large': {
        return css`
          height: 40px;
          padding: 0 ${theme.spacing(3)} ${showSelect ? '1px' : '0'} ${theme.spacing(4)};
          border-radius: ${theme.spacing(2)};
          & > .material-icons-round {
            right: 8px;
          }
        `;
      }
      case 'xlarge': {
        return css`
          height: 44px;
          padding: 0 ${theme.spacing(4)} ${showSelect ? '1px' : '0'} ${theme.spacing(5)};
          border-radius: ${theme.spacing(2)};
          & > .material-icons-round {
            right: 10px;
          }
        `;
      }
      default:
        return '';
    }
  }};
  ${({ hasError, theme }) => {
    if (hasError) {
      return css`
        border: 2px solid ${theme.palette.danger.dark};
        box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.1);
      `;
    }
  }}

  // removing the border and raduis from the bottom when the select is shown
  ${({ showSelect, placement }) =>
    showSelect && placement.includes('bottom')
      ? css`
          border-bottom: unset;
          border-bottom-left-radius: unset;
          border-bottom-right-radius: unset;
        `
      : showSelect && placement.includes('top')
      ? css`
          border-top: unset;
          border-top-left-radius: unset;
          border-top-right-radius: unset;
        `
      : ''}

  &:hover,
  &:focus {
    filter: brightness(85%);
  }
  &:active {
    filter: brightness(70%);
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledIconsContainer = styled.div`
  display: flex;
`;

const StyledSelect = styled.div<{
  size: SelectSize;
  customWidth?: number | 'maxOptionWidth';
  height: number;
  usedPlacement: SelectPlacement;
  container: DOMRect;
  select: { width: number; height: number };
  zIndex: 'high' | 'highest';
  containerWidth: string;
  withLabel: boolean;
}>`
  position: absolute;
  width: ${({ customWidth, container }) => {
    if (customWidth === 'maxOptionWidth') {
      return `unset`;
    }
    return `${customWidth ?? container.width}px`;
  }};
  min-width: ${({ customWidth, containerWidth }) => {
    if (customWidth === 'maxOptionWidth' && containerWidth.includes('px')) {
      return containerWidth;
    }
  }};
  max-height: ${({ height }) => `${height}px`};
  overflow-y: auto;
  z-index: ${({ theme, zIndex }) => theme.zIndex[zIndex]};
  border: 1px solid ${({ theme }) => theme.palette.fieldGrey};
  border-top: unset;
  background-color: ${({ theme }) => theme.palette.white};

  ${({
    usedPlacement,
    container: { top, left, width, height },
    select: { height: selectHeight, width: selectWidth },
    customWidth,
    withLabel,
  }) => {
    const usedWidth = customWidth === 'maxOptionWidth' ? selectWidth : customWidth ?? selectWidth;
    switch (usedPlacement) {
      case 'topStart':
        return css`
          border-top: 1px solid ${({ theme }) => theme.palette.fieldGrey};
          border-bottom: unset;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: ${({ theme }) => theme.spacing(1)};
          border-top-right-radius: ${({ theme }) => theme.spacing(1)};
          top: calc(${top}px - ${selectHeight}px + ${window.scrollY}px + ${withLabel ? '35px' : '0px'});
          left: calc(${left}px + ${window.scrollX}px);
        `;
      case 'topEnd':
        return css`
          border-top: 1px solid ${({ theme }) => theme.palette.fieldGrey};
          border-bottom: unset;
          border-bottom-left-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: ${({ theme }) => theme.spacing(1)};
          border-top-right-radius: ${({ theme }) => theme.spacing(1)};
          top: calc(${top}px - ${selectHeight}px + ${window.scrollY}px + ${withLabel ? '35px' : '0px'});
          left: calc(${left}px + ${window.scrollX}px - ${usedWidth}px + ${width}px);
        `;
      case 'bottomStart':
        return css`
          top: calc(${top}px + ${height}px + ${window.scrollY}px);
          left: calc(${left}px + ${window.scrollX}px);
        `;
      case 'bottomEnd':
        return css`
          top: ${top + height + window.scrollY}px;
          left: ${left - usedWidth + width + window.scrollX}px;
        `;
    }
  }};

  ${({ size, theme, usedPlacement }) => {
    if (!usedPlacement.includes('top')) {
      switch (size) {
        case 'tiny':
        case 'small': {
          return css`
            border-bottom-left-radius: ${theme.spacing(1)};
            border-bottom-right-radius: ${theme.spacing(1)};
          `;
        }
        case 'medium':
        case 'large':
        case 'xlarge': {
          return css`
            border-bottom-left-radius: ${theme.spacing(2)};
            border-bottom-right-radius: ${theme.spacing(2)};
          `;
        }
        default:
          return '';
      }
    } else {
      switch (size) {
        case 'tiny':
        case 'small': {
          return css`
            border-top-left-radius: ${theme.spacing(1)};
            border-top-right-radius: ${theme.spacing(1)};
          `;
        }
        case 'medium':
        case 'large':
        case 'xlarge': {
          return css`
            border-top-left-radius: ${theme.spacing(2)};
            border-top-right-radius: ${theme.spacing(2)};
          `;
        }
        default:
          return '';
      }
    }
  }}
`;

const StyledGroup = styled.div<{ selected?: boolean }>`
  height: ${({ theme }) => `${theme.spacing(9.5)}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  background-color: ${({ theme }) => theme.palette.backgroundGrey};
  color: ${({ theme, selected }) => (selected ? theme.palette.primary.main : theme.palette.midnight)};
  font-weight: ${({ theme, selected }) => (selected ? theme.font.weight.semibold : theme.font.weight.regular)};
  font-size: ${({ theme }) => theme.font.size.small};
  cursor: pointer;

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledSelectPlaceholder = styled.div`
  height: ${({ theme }) => `${theme.spacing(7)}`};
  padding: ${({ theme }) => `${theme.spacing(2)} ${theme.spacing(4)}`};
  color: ${({ theme }) => theme.palette.granite};
  font-size: ${({ theme }) => theme.font.size.tiny};
  line-height: ${({ theme }) => theme.font.lineheight.tiny};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StyledSearchContainer = styled.div<{
  zIndex: 'high' | 'highest';
}>`
  padding: ${({ theme }) => `${theme.spacing(3)} ${theme.spacing(4)}`};
  position: sticky;
  top: 0;
  background-color: white;
  z-index: ${({ theme, zIndex }) => theme.zIndex[zIndex]};
`;

const StyledSelectItem = styled.div<{ selected?: boolean }>`
  min-height: ${({ theme }) => `${theme.spacing(9)}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(2)};
  padding: ${({ theme }) => `0 ${theme.spacing(3)} 0 ${theme.spacing(4)}`};
  background-color: ${({ theme, selected }) => (selected ? theme.palette.silver : theme.palette.white)};
  color: ${({ theme, selected }) => (selected ? theme.palette.primary.main : theme.palette.midnight)};
  cursor: pointer;

  span:not(.material-icons-round) {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary.offwhite};
  }
`;

function Select<Value = string>({
  data,
  size = 'small',
  width = '100%',
  placeholder = 'Select',
  value,
  customDisplayLabel,
  searchable = false,
  alignment = 'Start',
  clearable = false,
  expandableGroups = false,
  showCheckMark = true,
  selectHeight = 386,
  selectWidth,
  orderGroups = 'DESCENDING',
  bold = false,
  selectPlaceholder,
  label,
  tooltip,
  disabled,
  groupBy,
  renderValue,
  renderSelectItem,
  renderSelectGroup,
  onChange,
  onClose,
  onClick,
  onSearchChange,
  zIndex = 'high',
  placement,
  showArrow = true,
}: PropsWithChildren<SelectProps<Value>>) {
  const validation = useValidationContext();

  const [showSelect, setShowSelect] = useState(false);
  const {
    containerCallbackRef,
    containerRef,
    containerRect,
    elementRef: selectRef,
    elementCallbackRef: selectCallbackRef,
    elementDimensions: selectDimensions,
  } = usePortalPositioning(showSelect, setShowSelect);

  const searchInputRef = useRef<HTMLInputElement>(null);
  const selectedItemRef = useRef<HTMLDivElement>(null);

  const [innerValue, setInnerValue] = useState<Value | null>(null);
  const [search, setSearch] = useState('');
  const [groups, setGroups] = useState<Groups<Value>>({});

  // placement
  const distanceToBottom = window.innerHeight - (containerRef.current?.getBoundingClientRect().bottom || 0);
  const usedPlacement: SelectPlacement = placement
    ? placement
    : distanceToBottom > selectHeight
    ? `bottom${alignment}`
    : `top${alignment}`;

  const handleItemClick = (itemValue: Value) => {
    setInnerValue(itemValue);
    onChange?.(itemValue);
    setShowSelect(false);
  };

  const focusInput = () => {
    searchInputRef.current?.focus();
  };

  const isFirstRender = useFirstRender();
  useEffect(() => {
    // on close
    if (!showSelect) {
      // clear search on select close
      setSearch('');
      onSearchChange?.('');

      // if it is not the first render we invoke onClose
      if (!isFirstRender) {
        onClose?.(value ?? innerValue);
      }
    }

    if (showSelect) {
      // focus on show
      focusInput();
      // scroll to selected item
      selectedItemRef.current?.scrollIntoView?.({ block: 'center', inline: 'center' });
      // focus input on key down
      document.addEventListener('keydown', focusInput);
    }
    return () => document.removeEventListener('keydown', focusInput);
  }, [showSelect]);
  useOutsideAlerter(selectRef, () => setShowSelect(false));

  const searchedValuesData = useMemo(
    () => data.filter(item => item === 'Divider' || item.label.toLowerCase().includes(search.toLowerCase())),
    [data, search],
  );
  useEffect(() => {
    let order = 0;
    const grouped = searchedValuesData.reduce((groups, item) => {
      // ignoring dividers on grouping for
      if (item === 'Divider') {
        return groups;
      }
      const groupName = groupBy ? item[groupBy] : undefined;
      if (groupName) {
        groups[groupName] = groups[groupName] ?? { items: [], isExpanded: true, order: order++ };
        groups[groupName].items.push(item);

        return groups;
      }

      groups[''] = groups[''] ?? { items: [], isExpanded: true };
      groups[''].items.push(item);
      return groups;
    }, {} as Groups<Value>);
    setGroups(grouped);
  }, [searchedValuesData]);

  const handleGroupExpand = (groupName: string) => {
    setGroups({ ...groups, [groupName]: { ...groups[groupName], isExpanded: !groups[groupName].isExpanded } });
  };

  const selectedItem = data.find(
    item => item !== 'Divider' && (value !== undefined ? item.value === value : item.value === innerValue),
  ) as DataItem<Value>;
  const hasError = validation?.hasError && !selectedItem;
  const selectedItemText = selectedItem?.label ?? placeholder;

  const typographySize =
    size === 'small' || size === 'medium' ? 'small' : size === 'large' || size === 'xlarge' ? 'medium' : size;
  const iconSize = size === 'small' ? 4.5 : size === 'medium' ? 5 : size === 'large' ? 5.5 : size === 'xlarge' ? 6 : 4;

  return (
    <StyledContainer ref={containerCallbackRef} onKeyDown={focusInput} width={width} onClick={onClick}>
      {label && (
        <Typography weight="regular" display="inline-flex">
          {label}
          {!!tooltip && (
            <Tooltip content={tooltip} placement="top" display="inline-flex">
              <Icon name="info" size={5} marginRight={0} marginLeft={1} />
            </Tooltip>
          )}
        </Typography>
      )}
      <StyledValue
        hasError={hasError}
        size={size}
        placement={usedPlacement}
        disabled={disabled}
        onClick={() => !disabled && setShowSelect(!showSelect)}
        showSelect={showSelect}
        bold={bold}
      >
        {renderValue && selectedItem ? (
          renderValue(selectedItem.value, selectedItem)
        ) : (
          <Typography
            size={typographySize}
            color={selectedItem ? 'black' : 'secondary'}
            weight={bold ? 'semibold' : 'medium'}
          >
            {customDisplayLabel || selectedItemText}
          </Typography>
        )}
        <StyledIconsContainer>
          {clearable && selectedItem && (
            <Icon
              size={iconSize}
              name="close"
              color={colors.granite}
              rounded
              marginRight={0}
              onClick={e => {
                e.stopPropagation();
                onChange?.(null!);
                setInnerValue(null);
              }}
            />
          )}
          {showArrow && <Icon size={iconSize} name="expand_more" color={colors.blue[600]} rounded marginRight={0} />}
        </StyledIconsContainer>
      </StyledValue>
      {showSelect &&
        createPortal(
          <StyledSelect
            ref={selectCallbackRef}
            size={size}
            withLabel={!!label || !!customDisplayLabel}
            customWidth={selectWidth}
            height={selectHeight}
            usedPlacement={usedPlacement}
            container={containerRect}
            select={selectDimensions}
            containerWidth={width}
            zIndex={zIndex}
          >
            {selectPlaceholder && <StyledSelectPlaceholder>{selectPlaceholder}</StyledSelectPlaceholder>}
            {searchable && (
              <StyledSearchContainer zIndex={zIndex}>
                <SearchInput
                  ref={searchInputRef}
                  value={search}
                  setValue={value => {
                    setSearch(value);
                    onSearchChange?.(value);
                  }}
                  placeholder="search"
                  width="100%"
                />
              </StyledSearchContainer>
            )}
            {(selectPlaceholder || searchable) && <Divider noMargin />}
            {searchable && searchedValuesData.length === 0 ? (
              <StyledSelectItem>
                <Row vAlign="center" spacing="small">
                  <Icon size={5} name="dangerous" color={colors.granite} marginRight={0} rounded />
                  <Typography weight="regular">No results found</Typography>
                </Row>
              </StyledSelectItem>
            ) : (
              Object.entries(groups)
                .sort(([a], [b]) => (orderGroups === 'ASCENDING' ? a.localeCompare(b) : b.localeCompare(a)))
                .map(([groupName, group]) => (
                  <Fragment key={groupName}>
                    {!!groupName && (
                      <StyledGroup onClick={() => expandableGroups && handleGroupExpand(groupName)}>
                        {renderSelectGroup ? renderSelectGroup(groupName) : <>{groupName.toUpperCase()}</>}
                        {expandableGroups && (
                          <Icon
                            name={group.isExpanded ? 'expand_less' : 'expand_more'}
                            size={6}
                            color={colors.blue[600]}
                            marginRight={0}
                          />
                        )}
                      </StyledGroup>
                    )}
                    {group.isExpanded &&
                      group.items.map(item => {
                        const isSelected = value !== undefined ? value === item.value : innerValue === item.value;
                        return (
                          <StyledSelectItem
                            key={`${item.label}${item.value}`}
                            onClick={() => handleItemClick(item.value)}
                            selected={isSelected}
                            ref={isSelected ? selectedItemRef : undefined}
                          >
                            {renderSelectItem ? (
                              renderSelectItem(item.value, item)
                            ) : (
                              <Typography weight="regular">{item.label}</Typography>
                            )}
                            {showCheckMark && isSelected && (
                              <Icon size={5} name="done" color={colors.blue[600]} rounded />
                            )}
                          </StyledSelectItem>
                        );
                      })}
                  </Fragment>
                ))
            )}
          </StyledSelect>,
          document.body,
        )}
    </StyledContainer>
  );
}
export default Select;
