import React from 'react';
import { useHistory } from 'react-router-dom';
import { mutate } from 'swr';

import useActiveOrganisationId from '../../../../components/contexts/OrganisationContext/useActiveOrganisationId';
import fetcher from '../../../../utils/fetcher';
import launchPopup from '../../../../utils/launchPopup';
import useConnection from '../../ConnectionsContext/useConnection';
import ConnectionCard from '../ConnectionCard';

export interface QuickbooksConnectionCardProps {
  hide: 'connected' | 'notconnected';
  isOnboarding?: boolean;
  selectedOrganisationId?: number;
}

const QuickbooksConnectionCard: React.FC<React.PropsWithChildren<QuickbooksConnectionCardProps>> = ({
  hide,
  isOnboarding,
  selectedOrganisationId,
}) => {
  const history = useHistory();

  const connectionName = 'quickbooks';
  const connection = useConnection(connectionName);
  let organisationId = useActiveOrganisationId();
  if (selectedOrganisationId) {
    // If we are onboarding, we want to use the selected organisation id
    organisationId = selectedOrganisationId;
  }

  const handleConnect = () => {
    const connectUrl = `/oauth/connect-to-quickbooks`;
    const urlParams = new URLSearchParams();
    if (selectedOrganisationId) {
      urlParams.append('subsidiary_id', selectedOrganisationId.toString());
    }
    if (isOnboarding) {
      urlParams.append('is_onboarding', 'true');
      launchPopup(`${connectUrl}?${urlParams}`);
    } else {
      launchPopup(connectUrl);
    }
  };

  const handleConfigure = async () => {
    history.push(`/oauth/${connectionName}`);
  };

  if (connection.status === hide) {
    return null;
  }
  const handleDisconnect = async () => {
    const result = await fetcher('/api/v1/connections/quickbooks/disconnect', {
      method: 'POST',
    });
    await mutate(`/api/v1/${organisationId}/connections`);
    return result;
  };

  return (
    <ConnectionCard
      name={connectionName}
      logo={'/images/logos/quickbooks-banner.png'}
      connectImg={'logos/quickbooks-connect.png'}
      connectImgHover={'logos/quickbooks-connect-hover.png'}
      handleConnect={handleConnect}
      handleConfigure={handleConfigure}
      handleDisconnect={handleDisconnect}
      error={connection.status === 'error' && connection.error}
    />
  );
};

export default QuickbooksConnectionCard;
