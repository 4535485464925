import { MetricSchema2 } from 'scalexp/utils/metrics/metricSchema2';
import useSWR from 'swr';

import { User } from '../components/contexts/DeprecatedUserContext';
import { CurrencyCode } from '../components/molecules/CurrencySelect/currencySupport';

export type RatesForDates = Record<string, number>;

export interface Rates {
  AVERAGE: RatesForDates;
  LAST_DAY: RatesForDates;
}

// Retrieval of current user

type NotAuthenticated = {
  error: boolean;
  code: 'NOT_AUTHENTICATED';
};

export const useSelf = () => {
  const { data, error, ...theRest } = useSWR<User, NotAuthenticated>(`/api/v1/users/self`, {
    shouldRetryOnError: false,
  });

  return { data, error, ...theRest };
};

export type Organisation = {
  name: string;
  name_short: string;
  organisation_id: number;
  auth0_id: string;
  logo_url?: string;
  landing_page: LandingPageConfig | null;
  default_currency_code: CurrencyCode;
  default_deferred_revenue_config_id?: string;
  financial_year_start?: number;
  consolidation_type: 'SINGLE' | 'SUBSIDIARY' | 'PARENT';
  parent: { id: number; name: string; auth0_id: string } | null;
  children: Organisation[];
  default_budget_set_id: number;
  default_consolidated_budget_set_id: number;
  prepayment_accounts: string[];
  balance_sheet_account?: string;
  primary_brand_colour: string | null;
  secondary_brand_colour: string | null;
  show_impact_colours: boolean;
  exclude_zeroed_accounts: boolean;
  upsell_leeway_type: 'MONETARY' | 'PERCENTAGE';
  upsell_leeway_value: number;
  downgraded_leeway_type: 'MONETARY' | 'PERCENTAGE';
  downgraded_leeway_value: number;
  is_onboarding_paused?: boolean;
  is_mfa_enabled?: boolean;
  is_invoice_stack_enabled?: boolean;
  upgrade_requested?: boolean;
  use_case?: Array<string>;
  period_lock_date?: string;
  completed_tasks: string[];
  prepayment_threshold: number;
  company_sector: string;
  enable_renewals?: boolean;
  enable_prepayments?: boolean;
  date_format?: string;
  enable_consolidated_customers_page?: boolean;
  balance_sheet_accrual_account?: string;
  prepaid_bills_location?: 'p_and_l' | 'balance_sheet' | 'p_and_l_with_balance_sheet';
  default_prepayment_account_id?: string;
  deferred_journal_version: 'deprecated' | 'new' | 'both';
};

interface Shortcut {
  url: string;
  title: string;
  category: string;
}

interface Shortcuts {
  [key: string]: Shortcut;
}

interface KPICards {
  [key: string]: MetricSchema2;
}

export interface LandingPageConfig {
  chartConfigId: number;
  chartId: string;
  steps: 1 | 2 | 3;
  shortcuts: Shortcuts;
  kpiCards: KPICards;
}
// Data accountSummary/Account retrieval and manipulation

export type OrganisationId = number;
